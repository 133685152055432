/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import FooterContentFunc from "./footerContent";
import AnchorElement from "../../functions/AnchorElement.jsx";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import { I18nContext } from "../../i18n/index";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
function Footer() {
  const data = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "logoRT.png" }) {
        childImageSharp {
          fixed(width: 210) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const year = new Date().getFullYear();

  const { translate } = React.useContext(I18nContext);

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [footerContent, setFooterContent] = useState({});

  const twitterLogo =
    "https://ik.imagekit.io/ofb/ofb/twitter_DkmqzF6MF.svg?h-38.5,w-38.5";
  const ytLogo =
    "https://ik.imagekit.io/ofb/ofb/youtube_l1kBPCnlm.svg?h-38.5,w-38.5";
  const facebookLogo =
    "https://ik.imagekit.io/ofb/ofb/facebook_ouzk_ScMN.svg?h-38.5,w-38.5";
  useEffect(() => {
    setFooterContent(FooterContentFunc(langState, loginNameState));
    return () => {
      setFooterContent({});
    };
  }, [langState]);

  const [activeFooterSection, setActiveFooterSection] = useState("");

  const renderLinks = arrContent => {
    return arrContent.map((content, i) => (
      <li {...content.parent.attributes} key={i}>
        {content.children.map((childContent, i) => {
          return <AnchorElement {...childContent} key={i} />;
        })}
      </li>
    ));
  };

  const finePrint = (
    <small>
      Powered by <a href="http://www.avetticommerce.com">Avetti Commerce</a>
      <br />
      Copyright &copy; {year} The Rotary Club of Barrie
    </small>
  );
  if (footerContent && Object.keys(footerContent).length > 0) {
    if (isMobileState) {
      const handleOnClickFooterSection = name => {
        setActiveFooterSection(activeFooterSection !== name && name);
      };

      const handleWhatIconToDisplay = footerSectionName => {
        return activeFooterSection === footerSectionName ? (
          <MdRemoveCircle />
        ) : (
          <MdAddCircle />
        );
      };
      console.info("activeFooterSection", activeFooterSection);

      return (
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              <div className="footer_Line">
                <div>
                  <div className="footerLogo pull-left">
                    <Img
                      fixed={data.Logo.childImageSharp.fixed}
                      alt="Avetti eCommerce Logo"
                    />
                  </div>
                </div>
              </div>
              {/* Mobile Version */}
              <div className="footer-container">
                <div className="liHead first">
                  <Link to="/donate">Donate to Rotary</Link>
                </div>

                <div className="liHead">
                  <a href="https://form.jotform.com/242396063013046" target="_blank">Refer a Donor</a>
                </div>

                {/* <div className="">
                  <Link to="/news">News</Link>
                </div> */}

                <div
                  className="liHead"
                  id="about-menu"
                  onClick={() => handleOnClickFooterSection("about")}
                  data-target="about"
                  aria-controls="about"
                  aria-expanded={activeFooterSection === "about"}
                >
                  {translate("vm.footer.learnmore")}
                  <i className="material-icons footer-icon" data-target="about">
                    {handleWhatIconToDisplay("about")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  aria-labelledby="about-menu"
                  role="region"
                  id="about"
                  style={{
                    display: activeFooterSection === "about" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.about)}</ul>
                </div>

                <div
                  className="liHead"
                  id="shop-menu"
                  onClick={() => handleOnClickFooterSection("shop")}
                  data-target="shop"
                  aria-controls="shop"
                  aria-expanded={activeFooterSection === "shop"}
                >
                  {translate("vm.footer_contact")}
                  <i className="material-icons footer-icon" data-target="shop">
                    {handleWhatIconToDisplay("shop")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  id="shop"
                  aria-labelledby="shop-menu"
                  role="region"
                  style={{
                    display: activeFooterSection === "shop" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.shop)}</ul>
                </div>

                {/* <div
                  className="liHead"
                  id="events-menu"
                  onClick={() => handleOnClickFooterSection("events")}
                  data-target="events"
                  aria-controls="events"
                  aria-expanded={activeFooterSection === "events"}
                >
                  Past Events
                  <i
                    className="material-icons footer-icon"
                    data-target="events"
                  >
                    {handleWhatIconToDisplay("events")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  id="events"
                  aria-labelledby="events-menu"
                  role="region"
                  style={{
                    display: activeFooterSection === "events" ? "block" : "none"
                  }}
                >
                  <ul>
                    <li>
                      <Link to="/spring-tonic">Spring Tonic</Link>
                    </li>
                    <li>
                      <Link to="/rvh-donation">RVH Donation</Link>
                    </li>
                    <li>
                      <Link to="/ice-fishing-raffle">Ice Fishing Raffle</Link>
                    </li>
                    <li>
                      <Link to="/presidents-bash">President's Bash</Link>
                    </li>
                    <li>
                      <Link to="/hockey-pool">Hockey Draft 2021-2022</Link>
                    </li>
                    <li>
                      <Link to="/halloweencontest">Halloween Contest</Link>
                    </li>
                    <li>
                      <Link to="/barrie-rotary-auction">Online Auction</Link>
                    </li>
                    <li>
                      <Link to="/first-lady-fundraiser">
                        First Lady Fundraiser
                      </Link>
                    </li>
                    <li>
                      <Link to="/virtual-euchre-tournament">
                        Virtual Euchre Tournament
                      </Link>
                    </li>
                    <li>
                      <Link to="/50th-anniversary-of-earth-day">
                        Earth Day Event
                      </Link>
                    </li>
                    <li>
                      <Link to="/fun-run">
                        Fun Run
                      </Link>
                    </li>
                    <li>
                      <Link to="/sonic">
                        Sonic Coalition Concert
                      </Link>
                    </li>
                  </ul>
                </div> */}

                <div
                  className="menu-footer"
                  id="socialMedia"
                  aria-labelledby="socialMedia-menu"
                  role="region"
                  style={{
                    display: "block"
                  }}
                >
                  {/* <ul>{renderLinks(footerContent.socialMedia)}</ul> */}
                  <div>
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px"
                      }}
                    >
                      <li className="socialMedia">
                        <a
                          href="https://www.facebook.com/barrierotary/"
                          target="_blank"
                        >
                          <img
                            className="filter-gray"
                            src={facebookLogo}
                            alt="Facebook Logo"
                          />
                        </a>
                      </li>
                      <li className="socialMedia">
                        <a
                          href="https://twitter.com/barrierotary"
                          target="_blank"
                        >
                          <img
                            className="filter-gray"
                            src={twitterLogo}
                            alt="Twitter Logo"
                          />
                        </a>
                      </li>
                      <li className="socialMedia">
                        <a
                          href="https://www.youtube.com/channel/UCn-lWtaijFiKugK6JVOWE-w"
                          target="_blank"
                        >
                          <img
                            className="filter-gray"
                            src={ytLogo}
                            alt="Youtube Logo"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="finePrint">{finePrint}</div>
            </div>
          </div>
        </footer>
      );
    } else {
      return (
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              <div className="footer-container" style={{ paddingTop: "20px" }}>
                <div className="menu-footer" id="socialMedia">
                  <div className="footerLogo pull-left">
                    <Img
                      fixed={data.Logo.childImageSharp.fixed}
                      alt="Avetti eCommerce Logo"
                    />
                  </div>
                </div>

                {/* <div className="liHead" id="about-menu">
                  {translate("vm.footer.learnmore")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-about-expand"
                  ></i>
                </div> */}
                <div className="menu-footer" id="about">
                  <ul>
                    {renderLinks(footerContent.about)}
                    {renderLinks(footerContent.shop)}
                    {/* <li>
                      <Link to="/news">News</Link>
                    </li> */}
                  </ul>
                </div>
                <div className="menu-footer" id="past">
                  {/* <strong>Past Events:</strong>
                  <ul style={{ marginTop: "10px" }}>
                    <li>
                      <Link to="/spring-tonic">Spring Tonic</Link>
                    </li>
                    <li>
                      <Link to="/rvh-donation">RVH Donation</Link>
                    </li>
                    <li>
                      <Link to="/ice-fishing-raffle">Ice Fishing Raffle</Link>
                    </li>
                    <li>
                      <Link to="/presidents-bash">President's Bash</Link>
                    </li>
                    <li>
                      <Link to="/hockey-pool">Hockey Draft 2021-2022</Link>
                    </li>
                    <li>
                      <Link to="/halloweencontest">Halloween Contest</Link>
                    </li>
                    <li>
                      <Link to="/barrie-rotary-auction">Online Auction</Link>
                    </li>
                    <li>
                      <Link to="/first-lady-fundraiser">
                        First Lady Fundraiser
                      </Link>
                    </li>
                    <li>
                      <Link to="/virtual-euchre-tournament">
                        Virtual Euchre Tournament
                      </Link>
                    </li>
                    <li>
                      <Link to="/50th-anniversary-of-earth-day">
                        Earth Day Event
                      </Link>
                    </li>
                    <li>
                      <Link to="/fun-run">
                        Fun Run
                      </Link>
                    </li>
                    <li>
                      <Link to="/sonic">
                        Sonic Coalition Concert
                      </Link>
                    </li>
                  </ul> */}
                </div>
                {/* <div className="liHead" id="shop-menu">
                  {translate("vm.footer_contact")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-shop-expand"
                  ></i>
                </div> */}
                {/* <div className="menu-footer" id="shop">
                  <ul>{renderLinks(footerContent.shop)}</ul>
                </div> */}
                {/* <div className="liHead" id="socialMedia-menu">
                  {translate("socialMedia")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-socialMedia-expand"
                  ></i>
                </div> */}
                <div>
                  <span style={{ marginBottom: "10px", fontSize: "14px" }}>
                    SOCIAL MEDIA
                  </span>
                  <ul style={{ display: "flex", paddingTop: "10px" }}>
                    <li className="socialMedia">
                      <a
                        href="https://www.facebook.com/barrierotary/"
                        target="_blank"
                      >
                        <img
                          className="filter-gray"
                          src={facebookLogo}
                          alt="Facebook Logo"
                        />
                      </a>
                    </li>
                    <li className="socialMedia">
                      <a
                        href="https://twitter.com/barrierotary"
                        target="_blank"
                      >
                        <img
                          className="filter-gray"
                          src={twitterLogo}
                          alt="Twitter Logo"
                        />
                      </a>
                    </li>
                    <li className="socialMedia">
                      <a
                        href="https://www.youtube.com/channel/UCn-lWtaijFiKugK6JVOWE-w"
                        target="_blank"
                      >
                        <img
                          className="filter-gray"
                          src={ytLogo}
                          alt="Youtube Logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <ul>{renderLinks(footerContent.socialMedia)}</ul> */}
            </div>
          </div>
          <div
            id="finePrint"
            style={{ background: "#ccc", marginTop: "30px", padding: "10px" }}
          >
            {finePrint}
          </div>
        </footer>
      );
    }
  } else {
    return null;
  }
}

export default Footer;
