import { PREVIEW, PROJECT_LINK } from "../../project-config";

export default function footerContent(langState, isLoggedIn) {
  let langCode = langState == "en" || langState == "tr" ? "" : `/${langState}`;

  const hideIfLoggedIn = () => {
    if (isLoggedIn) return { style: { display: isLoggedIn ? "none" : "" } };
    return {};
  };

  return {
    about: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              to: `/donate`
            },
            text: "Donate to Rotary"
          },
          {
            attributes: {
              to: `https://form.jotform.com/242396063013046`,
              target: "_blank"
            },
            text: "Refer a Donor"
          },
          {
            attributes: {
              to: `/terms-of-use`
            },
            text: "Terms Of Use"
          },
          {
            attributes: {
              to: `/shipping-information`
            },
            text: "Shipping Information"
          },
          {
            attributes: {
              to: `/return-policy`
            },
            text: "Return Policy"
          },
          {
            attributes: {
              to: `/privacy-policy`
            },
            text: "Privacy Policy"
          }
        ]
      }
    ],

    shop: [
      {
        parent: { attributes: {} },
        children: [
          // {
          //   attributes: {
          //     href: `mailto:ecommerce@avetti.com`
          //   },
          //   text: "E-mail : ecommerce@avetti.com"
          // },
          {
            attributes: {
              to: `https://www.barrierotary.com/`,
              target: "_blank"
            },
            text: "Contact Us"
          },
          // {
          //   attributes: {
          //     to: `/seller-registration`
          //   },
          //   text: "Seller Sign Up"
          // }
        ]
      }
    ],
    socialMedia: [
      {
        parent: { attributes: { className: "socialMedia" } },
        children: [
          // {
          //   attributes: {
          //     target: "_blank",
          //     href: "https://www.facebook.com/avetticommerce",
          //     rel : "noopener noreferrer"
          //   },
          //   image: `Facebook`
          // },
          

          {
            attributes: {
              target: "_blank",
              href: "https://twitter.com/barrierotary",
              rel : "noopener noreferrer"
            },
            image: `Twitter`
          },
          {
            attributes: {
              target: "_blank",
              href: "https://www.youtube.com/user/barrierotary",
              rel : "noopener noreferrer"
            },
            image: `Linkedin`
          }
        ]
      }
    ]
  };
}
